module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NeuSong","short_name":"NS","start_url":"/","icon":"./static/img/android-chrome-512x512.png","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3bc560bf3aff23d25d193b030dc25bda"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
