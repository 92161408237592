// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-selection-index-js": () => import("./../src/pages/book-selection/index.js" /* webpackChunkName: "component---src-pages-book-selection-index-js" */),
  "component---src-pages-devotional-hymns-index-js": () => import("./../src/pages/devotional-hymns/index.js" /* webpackChunkName: "component---src-pages-devotional-hymns-index-js" */),
  "component---src-pages-search-for-accompaniments-index-js": () => import("./../src/pages/search-for-accompaniments/index.js" /* webpackChunkName: "component---src-pages-search-for-accompaniments-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-accompaniments-page-js": () => import("./../src/templates/accompaniments-page.js" /* webpackChunkName: "component---src-templates-accompaniments-page-js" */),
  "component---src-templates-books-page-js": () => import("./../src/templates/books-page.js" /* webpackChunkName: "component---src-templates-books-page-js" */),
  "component---src-templates-chapters-page-js": () => import("./../src/templates/chapters-page.js" /* webpackChunkName: "component---src-templates-chapters-page-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-hymntunes-js": () => import("./../src/templates/hymntunes.js" /* webpackChunkName: "component---src-templates-hymntunes-js" */),
  "component---src-templates-title-page-js": () => import("./../src/templates/title-page.js" /* webpackChunkName: "component---src-templates-title-page-js" */),
  "component---src-templates-v-chapters-js": () => import("./../src/templates/v-chapters.js" /* webpackChunkName: "component---src-templates-v-chapters-js" */)
}

